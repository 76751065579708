<template>
    <div class="container">

        <div class="tool">
            <button class="button is-small" :class="{ 'active': activeButton === 1 }"
                @click="handleButtonClick(1)">发送1</button>
            <button class="button is-small" :class="{ 'active': activeButton === 2 }"
                @click="handleButtonClick(2)">发送2</button>
            <button class="button is-small" :class="{ 'active': activeButton === 3 }"
                @click="handleButtonClick(3)">发送3</button>
            <button class="button is-small" :class="{ 'active': activeButton === 4 }"
                @click="handleButtonClick(4)">发送4</button>
            <button class="button is-small" :class="{ 'active': activeButton === 5 }"
                @click="handleButtonClick(5)">发送5</button>
            <button class="button is-small" :class="{ 'active': activeButton === 6 }"
                @click="handleButtonClick(6)">发送6</button>


        </div>
        <table>
            <tr v-for="(row, rowIndex) in tableData" :key="rowIndex">
                <td v-for="(cell, cellIndex) in row" :key="cellIndex" :class="{ 'highlight': cell.highlight }" :style="'background: 3px scroll '+tableColors[cell.index]">
                    <div v-if="cell && cell.type === 1" class="circle yellow"></div>
                    <div v-else-if="cell && cell.type === 2" class="circle cyan"></div>
                    <div v-else-if="cell && cell.type === 3" class="circle black"></div>
                    <div v-else-if="cell && cell.type === 4" class="square hollow-black"></div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: 'Table2Component',
    data() {
        return {
            tableData:
                [
                    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
                ],
            tableColors:
                [
                    "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000",
                    "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000",
                    "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000",
                    "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000",
                    "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000",
                    "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000",
                    // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    // 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    // [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    // [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    // [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    // [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                    // [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
                ],
            button7Clicked: 1,
            activeButton: 1,
        };
    },
    created() {
        this.fetchTableData();
    },
    mounted() {
        this.emitter.on('table2', this.handleSomeEvent);
    },
    beforeUnmount() {
        this.emitter.off('table2', this.handleSomeEvent);
    },
    methods: {
        handleSomeEvent(e) {
            if (e.type == 'data') {
                // this.buttonTexts[e.id] = e.id;
                console.log('处理事件:', e);

                this.tableList[e.id - 1] = e.data;
                if (this.activeButton == e.id) {
                    this.setData(e.data);
                }

            }
            if (e.type == 'color') {
                // this.buttonTexts[e.id] = e.id;
                console.log('处理事件:', e);

                this.tableColors[e.id - 1] = e.color;
                // if (this.activeButton == e.id) {
                //     this.setData(e.data);
                // }

            }
            // if (e.type == 'class') {
            //     this.buttonClasses[e.id] = e.class;
            //     console.log('处理事件:', e, this.id);
            // }
            // if (e.type == 'time') {
            //     this.setTimer(e.time)
            // }

        },

        async fetchTableData() {
            try {
                const response = await fetch('https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/table1');
                const data = await response.json();
                let flatArray = data.data.array;
                this.setData(flatArray);
            } catch (error) {
                console.error('Error fetching table data:', error);
            }
        },

        setData(flatArray) {
            try {
                flatArray = flatArray.slice(-72); // 保留尾部72个数据
                const rows = 6;
                const cols = 12;
                let index = 0;
                this.tableData = Array.from({ length: rows }, () => Array.from({ length: cols }, () => ({})));

                for (let col = 0; col < cols; col++) {
                    for (let row = 0; row < rows; row++) {
                        var data = {
                            type: flatArray[index++],
                            index
                        };
                        this.tableData[row][col] = data || {};
                    }
                }
            } catch (error) {
                console.error('Error fetching table data:', error);

            }
        },




        handleButtonClick(buttonIndex) {
            if (buttonIndex === 7) {
                this.button7Clicked = !this.button7Clicked;
                // return;
            }
            console.log(`按钮${buttonIndex}被点击`);
            // 根据 buttonIndex 处理不同的点击事件
            const id = "-1";
            console.log(`按钮${buttonIndex}被点击`);
            fetch('https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/send?id=' + id + "&buttonIndex=" + buttonIndex, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ buttonIndex })
            });

            // 修改按钮自身的样式
            this.activeButton = buttonIndex;
        }
    }
}
</script>
<style lang="less" scoped>
.container {
    display: flex;
    flex-direction: row;
    border-top: 4px solid red;
}

.tool {
    background-color: #272727;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100px;

    // margin-top: -10px;
    &>button {
        margin-top: 4px;
        // margin-right: 20px;
    }

    &>button.active {
        background-color: red;
    }
}

table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    background-color: rgb(0, 0, 0);
}

td,
th,
tr {
    border: 2px solid #a6af20 !important;
    text-align: center;
    // vertical-align: middle;
    height: (100% / 6);
    width: (100% / 12);
    padding: 0 !important;

    div {
        display: inline-block;
        color: rgb(255, 0, 0);
    }
}

.circle {
    // margin-top: 5%;
    width: auto;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
}

.yellow {
    background-color: yellow;
}

.cyan {
    background-color: cyan;
}

.black {
    background-color: rgb(82, 82, 82);
}

.square {
    // margin-top: 5%;
    width: 100%;
    height: 100%;

    // aspect-ratio: 1.7;
}

.hollow-black {
    border: 2px solid rgb(255, 255, 255);
    // background-color: black;
}

.highlight {
    background-color: red;
}
</style>