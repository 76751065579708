<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  <div class="content1">
    <div class="floating-bar">
      <input type="text" style="width: 100px;" v-model="inputValue" placeholder="输入内容" />
      <input type="text" v-model="inputValue1" placeholder="输入内容" @keyup.enter="handleDebugButtonClick" />
      <button @click="handleDebugButtonClick">调试</button>
    </div>
    <ContentLeft />
    <ContentRight />
  </div>
  <div v-show="showOverlay" class="overlay">
    <video style="width: 80%; height: 80%;background: #000000b5;" ref="videoElement" muted autoplay></video>
    <div class="overlay-tool">
      <button @click=" videoId--; play()" class="button is-small">上一个</button>
      <button @click=" videoId++; play()" class="button is-small">下一个</button>
      <button class="button is-small" @click="play()">播放</button>
      <button @click=" flvPlayer.destroy(); showOverlay = false" class="button is-small">关闭</button>
    </div>
  </div>
</template>

<script>
import flvjs from "flv.js";
// import HelloWorld from './components/HelloWorld.vue'
import ContentLeft from './components/ContentLeft.vue'
import ContentRight from './components/ContentRight.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld
    ContentLeft,
    ContentRight
  },
  data() {
    return {
      inputValue: 'video',
      inputValue1: '',
      showOverlay: false,
      videoId: -1,
      flvPlayer: null,
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.video1.$el.style.backgroundColor = 'red';
    // });

    this.emitter.on("click", this.handleButtonClick);



  },
  deactivated() {
    this.emitter.off("click", this.handleButtonClick);
  },
  methods: {

    handleButtonClick(data) {
      var _this = this;
      if (data.type == "table1") {
        console.log("点击了按钮", data.id);
        // const response = await fetch('https://mock.presstime.cn/mock/675ac79745bad9c3dd7b5013/test01/table1?index' + this.activeButton + "");
        // var json = await response.json();
        var json = {
          url: 'http://8.218.24.102:9040/flv/live/game.flv'
        };

        _this.showOverlay = true;
        _this.videoId = data.id;
        // 在下一次 DOM 更新后执行
        if (_this.flvPlayer) {
          this.flvPlayer.destroy();
        }
        _this.$nextTick(() => {
          if (flvjs.isSupported()) {
            this.flvPlayer = flvjs.createPlayer({
              type: 'flv',
              url: json.url,
              // isLive: true,
              hasAudio: false,
            });
            // 此时 $refs.videoElement 已经可用
            console.log(_this.$refs);

            _this.flvPlayer.attachMediaElement(_this.$refs.videoElement);
            _this.flvPlayer.load();
            // _this.flvPlayer.play();
          }
        });


      }
    },
    play() {

    },

    handleDebugButtonClick() {
      // {"videoId":1,"type":"class","id":2,"class":"button is-small highlight"}
      // {"videoId":1,"type":"txt","id":2,"txt":"修改按钮"}
      try {
        const jsonData = JSON.parse(this.inputValue1);
        this.emitter.emit(this.inputValue, jsonData);
      } catch (error) {
        this.inputValue1 = "JSON 解析错误";
        console.error('JSON 解析错误:', error);
        alert('输入的内容不是有效的 JSON 格式');
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content1 {
  display: flex;
  height: 100%;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

body {}

.overlay-tool {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 10px;
  padding: 5px;
  background-color: #ccc;
  width: 80%;

  button {
    margin-right: 10px;
  }
}

.content-left {
  width: 270px;
  height: 100%;
  background-color: #000000;
  color: #fff;

  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;

    .table-title {
      font-size: 30px;

      th {
        padding: 0;
      }
    }
  }

  th,
  td {
    border: 3px solid #777777;
    text-align: center;
    padding: 8px;
  }

}

.content-right {
  flex: 1;
  height: 100%;
  background-color: #e74c3c;
}

table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #000;
  text-align: center;
  padding: 8px;
}

.floating-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  input {
    margin-right: 10px;
    padding: 5px;
    border: none;
    width: 70%;
    border-radius: 3px;
  }

  button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    background-color: #e74c3c;
    color: #fff;
    cursor: pointer;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-wrap:;
  // 内容竖向排列
  flex-direction: column;
  z-index: 1000;
}

.overlay video {
  max-width: 80%;
  max-height: 80%;
}
</style>
<style>
/* Bulma Import */
/* @import 'bulma/css/bulma.css'; */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

html,
body,
#app {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #2c3e50;
}


.content {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.content-left {
  width: 350px;
  height: 100%;
  background-color: #000000;
  color: #fff;


}

.content-right {
  flex: 1;
  height: 100%;
  background-color: #e74c3c;
}
</style>
