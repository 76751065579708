<template>
    <div class="content-right">

        <!-- <div class="block">8</div> -->
        <Video id="1" class="content-right-block"></Video>
        <!-- <Table1 class="content-right-block">1</Table1> -->
        <Video id="2" class="content-right-block"></Video>
        
        <Video id="3" class="content-right-block"></Video>
        <Video id="4" class="content-right-block"></Video>
        
        <Video id="5" class="content-right-block"></Video>
        <Table1 class="content-right-block">1</Table1>
        
        <Video id="6" class="content-right-block"></Video>
        <Table2 class="content-right-block">1</Table2>
    </div>
</template>
<script>
import Table1 from './Table1.vue';
import Table2 from './Table2.vue';
import Video from './Video.vue';
export default {
    name: "ContentRightComponent",
    components: {
        Video,
        Table1,
        Table2
    },
    data() {
        return {};
    },
};
</script>

<style lang="less" scoped>
.content-right {
    display: flex;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
}

.content-right-block {
    display: flex;
    width: 50%;
    height: 25%;
    // display: 
    background-color: rgb(37, 95, 25);
    color: white;
}
</style>
